<template>
  <v-footer
    
    padless
  >
    <v-card
      class="flex"
      flat
      tile
      color="light-blue"
    >
      <v-card-title :class="color">
        
   <v-row>
  
 
      <v-col
        cols="2"
          md="2"
         >
         <v-img
      :src="informacion.src"
       
        
        class="mr-3 hidden-xs-only"
        contain
        max-width="200"
         
      />     
 
 
      </v-col>

      
 
      </v-col>
      
      

       <v-col
        cols="12"
          md="3"
         >
        
        <v-card-title primary-title>
              Experiencia Pediatrica
            </v-card-title>  
              
            
          <v-card-text >  
            {{ informacion.descripcion_personal }}
            </v-card-text > 

           <v-divider  color="white">
       </v-divider>  
  <v-card-title primary-title>
             Redes Sociales
            </v-card-title>
      <v-card-actions>

        <br> 
        
        <v-btn
        
          v-for="icon in icons"
          :key="icon.index"
         :href="icon.url " 
         target="_blank"
          
          icon
        >
          <v-icon
           color="white"
           size="35px">
           
           {{ icon.icono }}
          </v-icon>
        </v-btn>
       
      </v-card-actions>

 
      </v-col>

        
 <v-divider vertical color="white">
       </v-divider>


       <v-col 
      cols="12"
        md="6"
            >
         
          <v-card-title primary-title>
         INFORMACIÓN
       </v-card-title>
        
      
      <v-card-text >  
       <v-timeline
        align-top
        dense
      >
        


          <v-timeline-item
          color="amber"
          small
        >
          <v-row class="pt-1">
            <v-col cols="2">
              <v-icon color="white" >mdi-hospital-building</v-icon>
            </v-col>
            <v-col>
              <strong>{{ informacion.direccion_empresa }} - {{ informacion.direccion_oficina }}</strong>
              
            </v-col>
          </v-row>
        </v-timeline-item>

         <v-timeline-item
          color="light-green accent-3"
          small
        >
          <v-row class="pt-1">
            <v-col cols="2">
              <v-icon color="white" >mdi-phone-lock</v-icon>
            </v-col>
            <v-col>
              <strong>{{ informacion.convencional }}</strong>
              
            </v-col>
          </v-row>
        </v-timeline-item>

         <v-timeline-item
          color="deep-orange darken-1"
          small
        >
          <v-row class="pt-1">
            <v-col cols="2">
              <v-icon color="white" >mdi-cellphone-sound</v-icon>
            </v-col>
            <v-col>
              <strong>{{ informacion.celular }}</strong>
              
            </v-col>
          </v-row>
        </v-timeline-item>


          <v-timeline-item
          color="yellow accent-2"
          small
        >
          <v-row class="pt-1">
            <v-col cols="2">
              <v-icon color="white" >mdi-email</v-icon>
            </v-col>
            <v-col>
              <strong>{{ informacion.correo_electronico }}</strong>
              
            </v-col>
          </v-row>
        </v-timeline-item>

       
 
      </v-timeline>
      </v-card-text >
  
        

      <v-spacer></v-spacer>
      </v-col>

   </v-row>
      </v-card-title>
 
<v-divider color="white">
</v-divider>
 
      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} — <strong> {{ informacion.nombre_gerente }} </strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
        url_:"",
      color:"light-blue white--text",
          icons: [
        
      ],
      informacion:[],

    }
    ),
     methods: {
          cargarGaleria() {
          //  console.log('holis');
            
             let that = this;
              var url =  this.url_ + "api/carrusel";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.items = response.data.carruseles
               // console.log(that.items);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
        cargarInformacion() {
           
            
             let that = this;
              var url =  this.url_ + "api/empresa";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.informacion = response.data.empresa;
                //console.log(that.informacion);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
              CargarRedes() {
           
            
             let that = this;
              var url =  this.url_ + "api/red_social";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.icons = response.data.redes_sociales;
              //  console.log(that.icons);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
    },
    mounted() {
       this.url_ = this.$store.state.link;
         this.cargarGaleria();
         this.cargarInformacion();
         this.CargarRedes();
    },

  }
</script>
 